import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormTextField from '@/components/form/fields/textField';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesRead } from '@/data/commerce/commerce.graphql';
import { convertCloverOrders } from '@/gatewayUtils/cloverUtils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { CloverOrder } from '@/types/clover';
import { Gateway, Location, MutationCommercesWriteArgs, QueryCommercesReadArgs } from '@/types/schema';
import { findItemsToSync } from '@/utils/findUnsyncedItems';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { gql } from '@apollo/client';
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Collapse, Paper, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationSelect from '../../../formSelects/locationSelect';

const PAGE_SIZE = 60;

export const syncCloverOrders = async (
	gateway: Gateway,
	location: Location,
	orders: CloverOrder[],
	removeDeletedAt?: boolean,
	staffId?: string | null ) => {
	const inputs = await convertCloverOrders( gateway, location.id, orders );
	await mutateGraphQL<MutationCommercesWriteArgs>( {
		mutation: gql`
			mutation CommercesCloverSync_9669($inputs: [CloverOrderValidator!]!) {
				commercesCloverSync(inputs: $inputs) {
					id
				}
			}
		`,
		variables: {
			inputs: inputs.map( ( input ) => ( {
				...input,
				staff    : staffId || staffId === null ? null : undefined,
				deletedAt: removeDeletedAt ? null : undefined,
			} ) ),
		},
	} );
};

export default function CloverOrdersModal() {
	const { enqueueSnackbar } = useSnackbar();
	const [ selectedIndex, setSelectedIndex ] = useState<number>( 0 );
	const { t } = useTranslation();
	const { user } = useUserInfo();
	const superAdmin = isSuperAdmin( user );
	
	const actionItems = [ {
		label      : t( 'commerce:import-single-order' ),
		description: t( 'commerce:import-clover-order-by-order-id' ),
		buttonText : t( 'commerce:import-single-order' ),
		onClick    : async ( location: Location, orderExternalId: string ) => {
			if ( location.gateway?.external !== 'CLOVER' ) throw new Error( 'Please choose a CLOVER location.' );
			await axios.post( '/api/processor/manage/getCloverOrders', {
				gatewayId       : location.gateway.id,
				orderExternalIds: [ orderExternalId ],
			} )
				.then( async ( { data } ) => {
					await syncCloverOrders( location.gateway as Gateway, location, data, true );
					enqueueSnackbar( t( 'commerce:order-was-synced-successfully' ), { variant: 'success' } );
				} )
				.catch( ( e ) => {
					throw e.response?.data?.details || 'Order could not be synced';
				} );
		},
	}, {
		label      : t( 'commerce:import-multiple-orders' ),
		description: t( 'commerce:import-recent-clover-orders' ),
		buttonText : t( 'commerce:import-multiple-orders' ),
		onClick    : async ( location: Location ) => {
			if ( location.gateway?.external !== 'CLOVER' ) throw new Error( 'Please choose a CLOVER location.' );
			
			try {
				const { data } = await axios.post( '/api/processor/manage/getCloverOrders', {
					gatewayId: location.gateway.id,
					page     : 0,
					pageSize : superAdmin ? 300 : PAGE_SIZE,
					// companyId: superAdmin && '265b71f8-6d67-477f-abcf-1bb08337e716',
				} );
				const cloverOrders = data;
				
				const { commercesRead } = await queryGraphQL<QueryCommercesReadArgs>( {
					query    : CommercesRead,
					variables: {
						options: {
							limit : PAGE_SIZE,
							filter: {
								externalId: { $in: cloverOrders.map( ( { id } ) => id ) },
							},
						},
					},
				} );
				const ordersToSync = findItemsToSync( cloverOrders, commercesRead?.items ?? [] );
				
				if ( isEmpty( ordersToSync ) ) return true;
				
				await syncCloverOrders( location.gateway as Gateway, location, ordersToSync as any );
				enqueueSnackbar( t( 'commerce:orders-synced-successfully' ), { variant: 'success' } );
			} catch ( e ) {
				console.error( e );
				throw e.response?.data?.details || 'Orders could not be synced';
			}
		},
	} ];
	
	return (
		<ResponsiveModalContainer title={t( 'commerce:import-latest-orders-from-clover' )}>
			<Form
				initialValues={{ location: null, orderExternalId: null }}
				onSubmit={() => {}}>
				{( formik ) => {
					const location = formik.values.location;
					const externalId = formik.values.orderExternalId;
					const error = externalId?.length < 12 || externalId?.length > 16;
					return (
						<Fragment>
							<LocationSelect
								name='location'
								label={t( 'common:sync-from' )}
								variables={{ options: { filter: { gateway: { active: true, external: 'CLOVER' } } } }}
								onAdd={undefined}
							/>
							<Collapse in={Boolean( location )}>
								<Stack spacing={1} my={2}>
									{actionItems.map( ( item, index ) => (
										<Paper
											key={index}
											sx={{
												'p'           : 2,
												'bgcolor'     : 'background.default',
												'borderRadius': 3,
												'border'      : 1,
												'transition'  : '.2s all',
												'borderColor' : index === selectedIndex ? 'primary.main' : 'divider',
												':hover'      : {
													cursor     : 'pointer',
													borderColor: 'primary.main',
												},
											}}
											onClick={() => setSelectedIndex( index )}>
											<Stack>
												<Typography variant='h5'>
													{item.label}
												</Typography>
												<Typography color='text.secondary'>
													{item.description}
												</Typography>
											</Stack>
											<Collapse in={selectedIndex === index}>
												<Stack spacing={1} sx={{ p: 1 }}>
													{index === 0 && (
														<FormTextField
															fullWidth
															name='orderExternalId'
															helperText={t( 'commerce:order-id-required-characters' )}
														/>
													)}
													<AsyncLoadingButton
														variant='contained'
														color='primary'
														startIcon={<ReceiptIcon/>}
														sx={{ alignSelf: 'end' }}
														disabled={index === 0 && ( error || !externalId )}
														onClick={async () => await item.onClick( location, externalId )}
														onSettled={() => null}>
														{item.buttonText}
													</AsyncLoadingButton>
												</Stack>
											</Collapse>
										</Paper>
									) )}
								</Stack>
							</Collapse>
						</Fragment>
					);
				}}
			</Form>
		</ResponsiveModalContainer>
	);
}
