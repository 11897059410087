import Actions from '@/components/actions';
import OpenInvoicesDialog from '@/components/openInvoices';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CustomFieldsRead } from '@/data/commerce/customField.graphql';
import { OrderWrite } from '@/data/commerce/order.graphql';
import { PricesRead } from '@/data/management/price.graphql';
import useAccountType from '@/helpers/useAccountType';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { useCloverLocations, useLocations } from '@/pages/formSelects/locationSelect';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import {
	Location,
	MutationOrderWriteArgs,
	PriceBase,
	QueryCustomFieldsReadArgs,
	QueryPricesReadArgs,
	Staff,
} from '@/types/schema';
import {
	AddCircle as AddCircleIcon,
	MarkEmailUnread as MarkEmailUnreadIcon,
	Sync as SyncIcon,
} from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { add } from 'date-fns';
import { omit, pick } from 'lodash-es';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CloverOrdersModal from '../cloverOrders';

export const getNewCommerceCompanyLocation = ( staff: Staff, locations: Location[], cloverLocations: Location[] ) => {
	const externalLocation = cloverLocations.length === 1 ? cloverLocations?.[ 0 ] : locations.length === 1
		? locations?.[ 0 ]
		: null;
	return externalLocation || ( staff?.locations?.length > 0 ? staff.locations[ 0 ] : null );
};

export function useCreateNewOrder(): () => Promise<void> {
	const router = useRouter();
	const { staff } = useUserInfo();
	const [ locations ] = useLocations();
	const [ cloverLocations ] = useCloverLocations();
	
	const queryClient = useQueryClient();
	const location = getNewCommerceCompanyLocation( staff, locations, cloverLocations );
	
	const company = staff?.company;
	const metadata = company?.metadata;
	
	return async (): Promise<void> => {
		try {
			const { pricesRead } = await queryGraphQL<QueryPricesReadArgs>( {
				query    : PricesRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							company   : company?.id,
							metadata  : { companyFee: true },
							order     : null,
							isSavedFee: true,
						},
					},
				},
			} );
			const { customFieldsRead } = await queryGraphQL<QueryCustomFieldsReadArgs>( {
				query    : CustomFieldsRead,
				variables: {
					options: {
						limit : 30,
						filter: {
							company: staff?.company?.id,
							order  : null,
						},
					},
				},
			} );
			const customFields = customFieldsRead?.items;
			const { orderWrite } = await mutateGraphQL<MutationOrderWriteArgs>( {
				mutation : OrderWrite,
				variables: {
					method      : 'New Order',
					customNumber: Boolean( company?.metadata?.customNumber ),
					input       : {
						staff          : staff?.id || null,
						taxPercent     : company.metadata?.tax || 0,
						companyLocation: location?.id || null,
						policy         : location?.policy?.id || null,
						standingDate   : new Date(),
						dueDate        : add( new Date(), { days: metadata?.dueDate || metadata?.serviceDateDue || 0 } ),
						serviceDate    : new Date(),
						customFields   : customFields?.map( ( customField ) => ( {
							...pick( customField, [ 'name', 'value' ] ),
							company: staff.company.id,
						} ) ),
						metadata: {
							signatureLine       : company?.metadata?.signatureLine,
							enableCashDiscount  : company?.metadata?.cashDiscount > 0,
							hideTips            : staff.company.metadata?.hideTips,
							enablePartialPayment: staff.company.metadata?.enablePartialPayment,
							requireSignature    : staff.company.metadata?.requireSignature,
							disableHousePayment : true,
						},
						prices: pricesRead?.items?.map( ( price: PriceBase ) => ( {
							...omit( price, [ 'id', 'company' ] ),
						} ) ),
					},
				},
			} );
			await router.push( `/dashboard/commerce/orders/${orderWrite.id}/edit` );
			await queryClient.invalidateQueries( [ 'user' ] );
		} catch ( e ) {
			throw e;
		}
	};
}

export default function OrderPageWrapperActions() {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const creatable = usePermissions( permissions.orders.write );
	const createNewOrder = useCreateNewOrder();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const { isCloverDevice } = useGetDeviceInfo();
	
	return (
		<Actions
			separated
			max={isMobile ? 2 : 3}
			items={[ /*creatable && isCloverDevice && {
				name       : t( 'commerce:new-invoice' ),
				onClick    : () => showModal( RegisterAppModal, { maxWidth: 'sm' } ),
				icon       : <AddCircleIcon/>,
				buttonProps: { variant: 'contained', color: 'primary' },
			},*/ creatable && {
				name       : t( 'common:new-order' ),
				icon       : <AddCircleIcon/>,
				buttonProps: { variant: 'contained', color: 'primary' },
				onClick    : createNewOrder,
			}, creatable && isCloverAccount && {
				name   : t( 'common:sync-clover-order' ),
				icon   : <SyncIcon/>,
				onClick: () => showModal( CloverOrdersModal, { maxWidth: 'sm' } ),
			}, creatable && {
				name   : t( 'common:open-orders' ),
				icon   : <MarkEmailUnreadIcon/>,
				onClick: () => showModal( OpenInvoicesDialog, { variant: 'fullPageDialog' } ),
			},
			]}
		/>
	);
}
