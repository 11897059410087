import GraphqlTable from '@/components/graphqlTable';
import OverflowTypography from '@/components/overflowTypography';
import UserPopover from '@/components/userPopover';
import currencyFormat from '@/helpers/currencyFormat';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Avatar, Box, Chip } from '@mui/material';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import { InvoicesRead } from '../../../../data/commerce/invoice.graphql';
import type { Order, QueryInvoicesReadArgs } from '../../../../types/schema';
import MobileRowPanel from '../components/mobileRow';
import { CalculateClients, CalculateDate, CalculateTotal } from '../components/tableHelpers';
import { InvoiceTemplateTableActions, useInvoiceTemplateActions } from './actions/templateActions';
import RowPanel from './rowPanel';

export const columns: Column<Order>[] = [ {
	accessor      : 'id',
	Header        : 'Id',
	disableSortBy : true,
	disableFilters: false,
	width         : 20,
	props         : { style: { minWidth: 50 } },
}, {
	accessor      : 'templateName',
	Header        : 'Name',
	width         : 20,
	disableFilters: false,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => value ?? '-',
}, {
	accessor       : ( row ) => row.client?.name || row.client?.contact,
	Header         : 'common:client',
	filterByKey    : 'client.contact',
	multipleFilters: [ 'name', 'contact' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
	Footer: ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor       : ( row ) => row.companyLocation?.name || row.companyLocation?.address?.line1,
	Header         : 'management:location',
	filterByKey    : 'companyLocation.name',
	multipleFilters: [ 'name', 'address.line1' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
}, {
	accessor      : 'po',
	Header        : 'common:po',
	disableFilters: true,
	Cell          : ( { value } ) => value || '',
	props         : { style: { minWidth: 135 } },
	width         : 12,
}, {
	accessor   : 'staff' as any,
	Header     : 'common:placed-by',
	sortType   : 'select',
	filterByKey: 'staff.user.firstName',
	width      : 10,
	props      : { style: { minWidth: 110 } },
	Cell       : ( { value } ) => (
		<UserPopover user={value?.user}>
			<Chip
				label={value?.user.firstName}
				size='small'
				avatar={(
					<Avatar
						alt={value?.user.firstName}
						src={value?.user?.image}
						sx={{
							border     : 1.5,
							borderColor: 'colors.opposite',
						}}
					/>
				)}
			/>
		</UserPopover>
	),
}, {
	accessor: 'updatedAt',
	Header  : 'common:updated',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'updatedAt' ),
}, {
	accessor: 'taxName',
	Header  : 'Tax Name',
	width   : 20,
	props   : { style: { minWidth: 168 } },
}, {
	accessor: 'taxTotal',
	Header  : 'common:tax',
	sortType: 'number',
	width   : 20,
	props   : { style: { minWidth: 138 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'taxTotal', true ),
}, {
	accessor: 'grandTotal',
	Header  : 'common:total',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 90 } },
	Cell    : ( { value } ) => currencyFormat( Math.abs( +value ) ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'grandTotal', true ),
} ];

export default function InvoiceTemplateTable() {
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	return (
		<CheckPermissions redirect='/dashboard/commerce' permissions={permissions.invoices.read}>
			<ResponsiveModalContainer
				title={t( 'commerce:invoice-templates' )}
				secondaryTitle={t( 'commerce:create-templates-use-every-time' )}>
				<Head><title>{t( 'commerce:invoice-template' )}| Invoiss</title></Head>
				<Box display='flex' justifyContent='end'>
					<InvoiceTemplateTableActions/>
				</Box>
				<GraphqlTable<Order, QueryInvoicesReadArgs>
					searchable
					queryKey='invoices'
					showFooter={false}
					query={InvoicesRead}
					variables={{ templatesOnly: true }}
					columns={columns}
					subscription={{ INVOICE: staff?.company.id ?? '' }}
					initialState={{ hiddenColumns: [ 'id', 'po', 'management:location', 'taxName' ] }}
					expandedComponent={( row ) => <RowPanel hideTags row={row}/>}
					useActions={useInvoiceTemplateActions}
					mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
				/>
			</ResponsiveModalContainer>
		</CheckPermissions>
	);
}
